import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { kinks } from '@turf/turf';
import { FC, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { useMap } from 'react-map-gl';
import { GeoJSONPolygon, parse as parseWkt } from 'wellknown';
import { Logger } from '../../helpers/Logger';
import assertUnreachable from '../../helpers/assertUnreachable';
import dwAxiosClient from '../../services/dwAxiosClient';
import { useBuildingEditContext } from './BuildingEditContext';
import BuildingEditOutlineSection from './BuildingEditOutlineSection';

export type BuildingFormState = {
  outline: {
    boundary: string | undefined;
    addr_housenumber: string;
    name: string;
    addr_street: string;
    addr_city: string;
    addr_state: string;
    addr_postcode: string;
    addr_country: string;
    height: number;
    floors: number;
    da_notes: string;
  };
  parts: Array<{
    boundaryWkt: string;
    heightLowMetres: number;
    heightMetres: number;
    heightLowFloors: number;
    heightFloors: number;
    notes: string;
  }>;
};

const allowedUnits = ['ft', 'm'] as const;
type AllowedUnits = typeof allowedUnits[number];

const convertToMeters = (valueInMeters: number, unit: AllowedUnits): number => {
  switch (unit) {
    case 'ft':
      return valueInMeters / 3.28084;
    case 'm':
      return valueInMeters;

    default:
      // If we get a TS error here, we have missed a case in the switch statement
      assertUnreachable(unit);
  }
};

const BuildingEditForm: FC = () => {
  const toast = useToast();
  const [buildingEditContextValues, buildingEditContextActions] = useBuildingEditContext();

  const { 'building-edit-map': map } = useMap();

  const [heightUnits, setHeightUnits] = useState<AllowedUnits>('ft');
  const [submittingForm, setSubmittingForm] = useState(false);

  const activeTabIndex =
    buildingEditContextValues.activePartNumber === 'outline'
      ? 0
      : buildingEditContextValues.activePartNumber + 1;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useFormContext<BuildingFormState>();

  const {
    fields: partsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'parts',
    rules: {
      minLength: 1,
    },
  });

  const outlineWkt = getValues('outline.boundary');

  const onSubmit = async (formData: BuildingFormState) => {
    const outlineHeight = formData.outline.height;
    const outlineFloors = formData.outline.floors;

    const tagPayload = {
      tags: {
        addr_housenumber: formData.outline.addr_housenumber,
        name: formData.outline.name,
        addr_street: formData.outline.addr_street,
        addr_city: formData.outline.addr_city,
        addr_state: formData.outline.addr_state,
        addr_country: formData.outline.addr_country,
        addr_postcode: formData.outline.addr_postcode,
        height: isNaN(outlineHeight) ? '' : convertToMeters(formData.outline.height, heightUnits),
        floors: isNaN(outlineFloors) ? '' : formData.outline.floors,
        da_notes: formData.outline.da_notes,
      },
    };

    const partsPayload = formData.parts.map((part) => {
      const partHeightInM = convertToMeters(part.heightMetres, heightUnits);

      const partLowHeightInM = convertToMeters(part.heightLowMetres, heightUnits);

      return {
        boundaryWkt: part.boundaryWkt,
        heightLowMetres: partLowHeightInM,
        heightMetres: partHeightInM,
        heightLowFloors: part.heightLowFloors,
        heightFloors: part.heightFloors,
        notes: part.notes,
      };
    });

    setSubmittingForm(true);

    try {
      const response = await dwAxiosClient.post('/Building/Building3dSubmit', partsPayload, {
        params: {
          boundaryWkt: formData.outline.boundary,
          tagsArrayJson: JSON.stringify(tagPayload),
        },
      });

      setSubmittingForm(false);

      if (response.status === 200) {
        toast({
          id: 'building-submit-success',
          status: 'success',
          duration: 2000,
          description: 'Building submitted successfully',
        });

        reset();
        if (map) {
          map.getMap().fire('draw.clearAll');
        }
      } else {
        Logger.error('Error submitting building', response.status, response.data);
        toast({
          id: 'building-submit-error',
          status: 'error',
          duration: 3000,
          description: 'Something went wrong submitting this building. Please try again later.',
          isClosable: true,
        });
      }
    } catch (e) {
      setSubmittingForm(false);
      Logger.error('Error submitting building', e);
      toast({
        id: 'building-submit-error',
        status: 'error',
        duration: 3000,
        description: 'Something went wrong submitting this building. Please try again later.',
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      as={'form'}
      onSubmit={handleSubmit(onSubmit)}
      borderRadius="md"
      borderColor="brand.300"
      borderStyle="solid"
      borderWidth="2px"
      p="4"
      flexDirection="column"
      overflow="hidden"
    >
      <Box flexGrow="0" flexShrink="0" mt="0" mb="3">
        <Heading as="h2" size="sm" mb="2">
          Settings
        </Heading>
        <RadioGroup
          mb="2"
          value={heightUnits}
          onChange={(newValue: AllowedUnits): void => {
            setHeightUnits(newValue);
          }}
        >
          <Stack direction="row">
            <Box>Units:</Box>
            {allowedUnits.map((unit) => {
              return (
                <Radio value={unit} key={`unit-button-${unit}`} id={`form-radio-${unit}`}>
                  {unit}
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>
      </Box>
      <Flex flexGrow="0" flexShrink="1" mt="0" mb="3" overflow="hidden" flexDirection="column">
        <Heading as="h2" size="sm" mb="2" flexShrink="0" flexGrow="0">
          Building Details
        </Heading>
        <Tabs
          index={activeTabIndex}
          onChange={(index) => {
            const newIndex = index === 0 ? 'outline' : index - 1;
            return buildingEditContextActions?.setActivePart(newIndex);
          }}
          size="sm"
          display="flex"
          flexDirection="column"
          flexGrow="0"
          flexShrink="1"
          overflow="hidden"
        >
          <TabList flexGrow="0" flexShrink="0" flexWrap="wrap">
            <Tab bgColor={errors.outline ? 'red.100' : 'none'}>Footprint</Tab>
            {partsFields.map((field, partNumber) => {
              const allPartsErrors = errors.parts;
              const partErrors =
                allPartsErrors && allPartsErrors.length && allPartsErrors.length > 0
                  ? allPartsErrors[partNumber]
                  : undefined;

              return (
                <Tab key={field.id} bgColor={partErrors ? 'red.100' : 'none'}>
                  <Box mr="2">Building Part {partNumber}</Box>
                  <IconButton
                    variant={'link'}
                    p={0}
                    icon={<BiTrash />}
                    onClick={(e) => {
                      e.stopPropagation();

                      remove(partNumber);

                      if (buildingEditContextValues.activePartNumber !== 'outline') {
                        if (buildingEditContextValues.activePartNumber === partNumber) {
                          const lastPartNumber = partsFields.length - 2;

                          if (lastPartNumber >= 0) {
                            buildingEditContextActions?.setActivePart(lastPartNumber);
                          } else {
                            buildingEditContextActions?.setActivePart('outline');
                          }
                        } else if (buildingEditContextValues.activePartNumber > partNumber) {
                          buildingEditContextActions?.setActivePart(
                            buildingEditContextValues.activePartNumber - 1
                          );
                        }
                      }
                    }}
                    aria-label={`Remove part ${partNumber}`}
                  >
                    Remove
                  </IconButton>
                </Tab>
              );
            })}
            <IconButton
              aria-label="Add new section"
              boxSize="30px"
              icon={<BiPlus />}
              variant="link"
              onClick={() => {
                const numberOfSections = partsFields.length;

                const defaultBoundary =
                  numberOfSections > 0
                    ? partsFields[numberOfSections - 1].boundaryWkt
                    : outlineWkt ?? '';

                append({
                  boundaryWkt: defaultBoundary,
                  notes: '',
                  heightMetres: 0,
                  heightLowMetres: 0,
                  heightLowFloors: 0,
                  heightFloors: 0,
                });

                buildingEditContextActions?.setActivePart(numberOfSections);
              }}
            >
              Plus
            </IconButton>
          </TabList>
          <TabPanels overflowY="auto" flexShrink="1">
            <TabPanel>
              <Box
                as="aside"
                fontSize="sm"
                mb="3"
                backgroundColor="blue.50"
                p="2"
                borderRadius="lg"
              >
                Draw an overall building footprint and provide address details.
                <br />
                Building height and number of floors must be provided, which should equal those
                provided in subsequent building parts. <br />
                This section does not create a 3D object, it is to set building footprint and
                provide overall details only.
              </Box>
              <BuildingEditOutlineSection
                errors={errors}
                register={register}
                heightUnits={heightUnits}
                copyValue={getValues('outline.boundary')}
              />
            </TabPanel>
            {partsFields.map((field, index) => {
              const allPartsErrors = errors.parts;
              const partErrors =
                allPartsErrors && allPartsErrors.length && allPartsErrors.length > 0
                  ? allPartsErrors[index]
                  : undefined;

              return (
                <TabPanel key={field.id}>
                  <FormControl isInvalid={!!(partErrors && partErrors.boundaryWkt)} mb="4">
                    <FormLabel>Part Boundary</FormLabel>
                    <InputGroup>
                      <Input
                        readOnly
                        {...register(`parts.${index}.boundaryWkt`, {
                          validate: {
                            isKinked: (wkt) => {
                              if (wkt) {
                                const geo = parseWkt(wkt);
                                if (geo) {
                                  const numberOfKinks = kinks(geo as GeoJSONPolygon);
                                  if (numberOfKinks.features.length > 0) {
                                    return 'Shape cannot cross itself';
                                  } else {
                                    return true;
                                  }
                                }
                                return 'Not Valid GeoJSON';
                              }
                              return 'Shape is required. Please draw a building shape.';
                            },
                          },
                        })}
                        id={`parts-${index}-boundaryWkt`}
                      />
                    </InputGroup>
                    <FormErrorMessage>{partErrors?.boundaryWkt?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!(partErrors && partErrors.heightMetres)} mb="4">
                    <FormLabel>Part Height ({heightUnits === 'm' ? 'm' : 'ft'})</FormLabel>
                    <FormHelperText mb="2">
                      Height of this building part only, excluding other parts
                    </FormHelperText>
                    <Input
                      {...register(`parts.${index}.heightMetres`, {
                        maxLength: { value: 255, message: 'Maximum 255 characters' },
                        valueAsNumber: true,
                        required: 'Please enter a height greater than zero',
                        min: { value: 1, message: 'Please enter a height greater than zero' },
                      })}
                      id={`parts-${index}-heightMetres`}
                      type="number"
                    />
                    <FormErrorMessage>{partErrors?.heightMetres?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!(partErrors && partErrors.heightLowMetres)} mb="4">
                    <FormLabel>Part Start Height ({heightUnits === 'm' ? 'm' : 'ft'})</FormLabel>{' '}
                    <FormHelperText mb="2">
                      Relates to the end height of the building part underneath this part
                    </FormHelperText>
                    <Input
                      {...register(`parts.${index}.heightLowMetres`, {
                        maxLength: { value: 255, message: 'Maximum 255 characters' },
                        min: { value: 0, message: 'Start height must be 0 or larger' },
                        valueAsNumber: true,
                      })}
                      id={`parts-${index}-heightLowMetres`}
                      type="number"
                    />
                    <FormErrorMessage>{partErrors?.heightLowMetres?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!(partErrors && partErrors.heightFloors)} mb="4">
                    <FormLabel>Number of Floors</FormLabel>{' '}
                    <FormHelperText mb="2">
                      Floors in this building part only, excluding other parts
                    </FormHelperText>
                    <Input
                      {...register(`parts.${index}.heightFloors`, {
                        maxLength: { value: 255, message: 'Maximum 255 characters' },
                        min: { value: 1, message: 'Number of floors must be 1 or larger' },
                        valueAsNumber: true,
                      })}
                      id={`parts-${index}-heightFloors`}
                      type="number"
                    />
                    <FormErrorMessage>{partErrors?.heightFloors?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!(partErrors && partErrors.heightLowFloors)} mb="4">
                    <FormLabel>Starting Floor Number</FormLabel>{' '}
                    <FormHelperText mb="2">
                      Relates to the end floor number of the building part underneath this part
                    </FormHelperText>
                    <Input
                      {...register(`parts.${index}.heightLowFloors`, {
                        maxLength: { value: 255, message: 'Maximum 255 characters' },
                        min: { value: 0, message: 'Starting floor number must be 0 or larger' },
                        valueAsNumber: true,
                      })}
                      id={`parts-${index}-heightLowFloors`}
                      type="number"
                    />
                    <FormErrorMessage>{partErrors?.heightLowFloors?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Notes</FormLabel>
                    <Input
                      {...register(`parts.${index}.notes`, {
                        maxLength: { value: 255, message: 'Maximum 255 characters' },
                      })}
                      id={`parts-${index}-notes`}
                    />
                  </FormControl>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Flex>
      <Flex flexGrow="0" flexShrink="0" mt="auto" mb="0">
        <Button type="submit" ml="auto" mr="0" isLoading={submittingForm} id="form-submit">
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default BuildingEditForm;
