import { Grid } from '@chakra-ui/react';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BuildingEditContextProvider } from './BuildingEditContext';
import BuildingEditForm, { BuildingFormState } from './BuildingEditForm';
import BuildingEditMap from './BuildingEditMap';

const BuildingEditContent: FC = () => {
  const formMethods = useForm<BuildingFormState>({
    defaultValues: {
      outline: {},
      parts: [],
    },
  });

  return (
    <FormProvider {...formMethods}>
      <BuildingEditContextProvider>
        <Grid
          width="100%"
          height="100%"
          overflow="hidden"
          flexGrow={1}
          templateColumns={{ md: '1fr', lg: '8fr 4fr' }}
          gridColumnGap="2"
        >
          <BuildingEditMap />
          <BuildingEditForm />
        </Grid>
      </BuildingEditContextProvider>
    </FormProvider>
  );
};

export default BuildingEditContent;
