import { useQuery } from '@tanstack/react-query';
import { parse } from 'wellknown';
import { Logger } from '../helpers/Logger';
import { isNeutralHost } from '../helpers/isNeutralHost';
import layerConfig, { neutralHostLayerConfig } from '../helpers/layerConfig';
import layerStyles, { defaultLayerStyles } from '../helpers/layerConfig.additionalLayers';
import { LayerConfig } from '../helpers/layerConfig.types';
import { useAppSelector } from '../hooks/useAppSelector';
import dwAxiosClient from './dwAxiosClient';

type GetLayersRawResponse = {
  boundingBoxWkt: string | null;
  description: string;
  layerId: number;
  name: string;
  visibleFromUtc: string | null;
  visibleToUtc: string | null;
  zoomFrom: number | null;
  zoomTo: number | null;
}[];

const fetchLayers = async (
  params: { countryCode: string | null; providerName: string | null },
  options: { signal?: AbortSignal }
): Promise<LayerConfig[]> => {
  const { countryCode, providerName } = params;
  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return layerConfig;
  }

  // This will be replaced by having all layers config be fetched via API
  const layerConfigToUse = isNeutralHost(providerName) ? neutralHostLayerConfig : layerConfig;

  try {
    const response = await dwAxiosClient.get<GetLayersRawResponse>('/Layers/GetLayers', {
      params: { countryCode, providerName },
      signal,
    });

    if (response.status === 200) {
      const { data } = response;

      const transformedLayers = data.flatMap((config) => {
        const layerId = config.layerId.toString();

        const parsedWkt = config.boundingBoxWkt ? parse(config.boundingBoxWkt) : null;

        const additionalConfig = layerStyles[layerId] ?? defaultLayerStyles;

        // This value can come from the API to make it dynamic
        const addClusterVal = config.name.toLowerCase().includes('cellmapper') ? true : false;
        if (layerId === '-1') {
          const TwoDConfig: LayerConfig = {
            ...config,
            layerId: '-1',
            zoomFrom: config.zoomFrom ?? 14,
            zoomTo: config.zoomTo ?? 24,
            defaultLayerOn: false,
            layerParts: [
              {
                cluster: addClusterVal,
                from: config.zoomFrom ?? 14,
                to: config.zoomTo ?? 24,
                type: 'geojson',
                layerChunkLevel: additionalConfig.chunkSize,
                layerStyles: additionalConfig.layerStyles,
              },
            ],
            fromApi: true,
            details: additionalConfig.details,
            filters: additionalConfig.filters,
            boundingBox: parsedWkt,
          };

          const threeDConfig = layerStyles['-1-3D'];

          const ThreeDConfig: LayerConfig = {
            ...config,
            layerId: '-1-3D',
            layerIdData: '-1',
            name: 'Building Outline 3D',
            zoomFrom: config.zoomFrom ?? 14,
            zoomTo: config.zoomTo ?? 24,
            defaultLayerOn: false,
            layerParts: [
              {
                layerIdData: -1,
                cluster: addClusterVal,
                from: config.zoomFrom ?? 14,
                to: config.zoomTo ?? 24,
                type: 'geojson',
                layerChunkLevel: threeDConfig.chunkSize,
                layerStyles: threeDConfig.layerStyles,
              },
            ],
            fromApi: true,
            details: threeDConfig.details,
            filters: threeDConfig.filters,
            boundingBox: parsedWkt,
          };

          // This order matters. We want 3D on top of 2D
          return [ThreeDConfig, TwoDConfig];
        } else {
          const layer: LayerConfig = {
            ...config,
            zoomFrom: config.zoomFrom ?? 14,
            zoomTo: config.zoomTo ?? 24,
            defaultLayerOn: false,
            layerParts: [
              {
                cluster: addClusterVal,
                from: config.zoomFrom ?? 14,
                to: config.zoomTo ?? 24,
                type: 'geojson',
                layerChunkLevel: additionalConfig.chunkSize,
                layerStyles: additionalConfig.layerStyles,
              },
            ],
            fromApi: true,
            details: additionalConfig.details,
            filters: additionalConfig.filters,
            boundingBox: parsedWkt,
          };

          return layer;
        }
      });

      return layerConfigToUse.concat(transformedLayers);
    }
  } catch (e) {
    Logger.error('Error fetching layers', e);
    return layerConfigToUse;
  }

  return layerConfigToUse;
};

const useLayersClient = () => {
  const countryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const providerName = useAppSelector((state) => state.contextSettings.selectedProviderName);

  return useQuery({
    queryKey: ['layers', providerName, countryCode],
    queryFn: ({ signal }) => {
      return fetchLayers({ countryCode, providerName }, { signal });
    },
    enabled: countryCode !== null && providerName !== null,
    staleTime: 1000 * 60 * 5,
  });
};

export default useLayersClient;
