import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { FaCloudDownloadAlt, FaInfoCircle } from 'react-icons/fa';
import { useDownloadLeadgenReport } from './useDownloadLeadgenReport';

type LeadGenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  featureId: string | number;
};

const LeadGenReportModal: FC<LeadGenModalProps> = (props) => {
  const { isOpen, onClose, featureId } = props;
  const [hexBinDiag, setHexBinDiag] = useState('');
  const [maxFloors, setMaxFloors] = useState('');

  const [debouncedHexBinDiag, setDebouncedHexBinDiag] = useState(hexBinDiag);
  const { mutate: downloadReport, isLoading } = useDownloadLeadgenReport();

  // Debounce effect: update debouncedHexBinDiag 0.5 seconds after user stops typing
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedHexBinDiag(hexBinDiag);
    }, 500);
    return () => clearTimeout(timer);
  }, [hexBinDiag]);

  const handleHexBinDiagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHexBinDiag(e.target.value);
  };

  const handleMaxFloorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxFloors(e.target.value);
  };

  const isHexBinDiagValid =
    debouncedHexBinDiag === '' ||
    (Number(debouncedHexBinDiag) >= 20 && Number(debouncedHexBinDiag) <= 200);

  const isMaxFloorsValid = maxFloors === '' || (Number(maxFloors) > 0 && Number(maxFloors) <= 20);

  const isDisabled =
    !(hexBinDiag !== '' && maxFloors !== '' && isHexBinDiagValid && isMaxFloorsValid) || isLoading;

  const handleDownloadReport = () => {
    downloadReport({
      featureId,
      hexBinDiag: Number(hexBinDiag),
      maxFloors: Number(maxFloors),
    });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      colorScheme="brand"
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>LeadGen Powerpoint Report</ModalHeader>
        <ModalCloseButton id="mno-analysis-button-close" colorScheme="black" />
        <ModalBody>
          <Heading size="sm" mb="4">
            For Building ID: {featureId}
          </Heading>
        </ModalBody>

        <ModalFooter>
          <Flex direction="row" mb="4" alignItems="flex-end">
            <Flex>
              {/* Hexbin Diagonal Field */}
              <FormControl isRequired isInvalid={hexBinDiag !== '' && !isHexBinDiagValid} mr="4">
                <Flex alignItems="start" direction="column">
                  <FormLabel htmlFor="hexbin-input">Hexbin Diagonal (ft)</FormLabel>
                  <InputGroup>
                    <Input
                      id="hexbin-input"
                      type="number"
                      min={20}
                      max={200}
                      value={hexBinDiag}
                      onChange={handleHexBinDiagChange}
                      w="150px" // Fixed width prevents expansion when error text is long
                    />
                  </InputGroup>

                  <Box minW="300px">
                    {hexBinDiag !== '' && !isHexBinDiagValid ? (
                      <FormErrorMessage mt="2">
                        Enter a value between 20 and 200 feet.
                      </FormErrorMessage>
                    ) : (
                      <FormHelperText>20-200 feet</FormHelperText>
                    )}
                  </Box>
                </Flex>
              </FormControl>
            </Flex>

            <Flex>
              {/* Max Floors Field */}
              <FormControl isRequired isInvalid={maxFloors !== '' && !isMaxFloorsValid} mr="4">
                <Flex alignItems="start" direction="column">
                  <Flex alignItems="center">
                    <FormLabel htmlFor="max-floors-input">Max Floors</FormLabel>
                    <Tooltip
                      label="Choose the number of floors you wish to report on. Floors with the highest measurement volumes are included in the report."
                      hasArrow
                      placement="top"
                    >
                      <Box mb="2">
                        <FaInfoCircle size="20" color="gray" style={{ cursor: 'pointer' }} />
                      </Box>
                    </Tooltip>
                  </Flex>
                  <InputGroup>
                    <Input
                      id="max-floors-input"
                      type="number"
                      min={1}
                      max={20}
                      value={maxFloors}
                      onChange={handleMaxFloorChange}
                      w="120px"
                    />
                  </InputGroup>

                  <Box minW="300px">
                    {maxFloors !== '' && !isMaxFloorsValid ? (
                      <FormErrorMessage mt="2">
                        Enter a valid number between 1 and 20.
                      </FormErrorMessage>
                    ) : (
                      <FormHelperText>1-20</FormHelperText>
                    )}
                  </Box>
                </Flex>
              </FormControl>
            </Flex>

            <Button
              id="download-leadgen-report-button"
              rightIcon={<FaCloudDownloadAlt size="30" />}
              onClick={handleDownloadReport}
              isDisabled={isDisabled}
              mb="10"
            >
              Download LeadGen Report
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeadGenReportModal;
