import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import { kinks } from '@turf/turf';
import { FC, Fragment } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { GeoJSONPolygon, parse as parseWkt } from 'wellknown';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import { BuildingFormState } from './BuildingEditForm';
import countries from './countries';

type BuildingEditOutlineSectionProps = {
  errors: FieldErrors<BuildingFormState>;
  register: UseFormRegister<BuildingFormState>;
  heightUnits: 'm' | 'ft';
  copyValue: string | null | undefined;
};

const BuildingEditOutlineSection: FC<BuildingEditOutlineSectionProps> = (props) => {
  const { errors, register, heightUnits, copyValue } = props;

  return (
    <Fragment>
      <FormControl isInvalid={!!(errors.outline && errors.outline?.boundary)} mb="3">
        <FormLabel>Building Footprint Boundary</FormLabel>
        <Flex alignItems="center">
          <Input
            readOnly
            {...register('outline.boundary', {
              validate: {
                isKinked: (wkt) => {
                  if (wkt) {
                    const geo = parseWkt(wkt);
                    if (geo) {
                      const numberOfKinks = kinks(geo as GeoJSONPolygon);
                      if (numberOfKinks.features.length > 0) {
                        return 'Shape cannot cross itself';
                      } else {
                        return true;
                      }
                    }
                    return 'Not Valid GeoJSON';
                  }
                  return 'Shape is required. Please draw a building shape.';
                },
              },
            })}
            id="field-boundary"
          />
          <CopyToClipboard
            ml={2}
            copyText={copyValue ?? ''}
            isDisabled={copyValue === null || copyValue === undefined || copyValue === ''}
          />
        </Flex>
        <FormErrorMessage>{errors.outline?.boundary?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_housenumber} mb="3">
        <FormLabel>Building Number</FormLabel>
        <Input
          {...register('outline.addr_housenumber', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-addr_housenumber"
        />
        <FormErrorMessage>{errors.outline?.addr_housenumber?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.name} mb="3">
        <FormLabel>Building Name</FormLabel>
        <Input
          {...register('outline.name', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-name"
        />
        <FormErrorMessage>{errors.outline?.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_street} mb="3">
        <FormLabel>Street</FormLabel>
        <Input
          {...register('outline.addr_street', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-addr_street"
        />
        <FormErrorMessage>{errors.outline?.addr_street?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_city} mb="3">
        <FormLabel>City</FormLabel>
        <Input
          {...register('outline.addr_city', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-addr_city"
        />
        <FormErrorMessage>{errors.outline?.addr_city?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_state} mb="3">
        <FormLabel>State</FormLabel>
        <Input
          {...register('outline.addr_state', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-addr_state"
        />
        <FormErrorMessage>{errors.outline?.addr_state?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_postcode} mb="3">
        <FormLabel>Postal/Zip Code</FormLabel>
        <Input
          {...register('outline.addr_postcode', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: {
              value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*'{0,1}[A-Za-zÀ-ÖØ-öø-ÿ0-9&*()#.-\s]*$/,
              message: 'Please use: letters, numbers, & * ( ) # . - and a single apostrophe',
            },
          })}
          id="field-addr_postcode"
        />
        <FormErrorMessage>{errors.outline?.addr_postcode?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.addr_country} mb="3">
        <FormLabel>
          Country{' '}
          <Box as="span" color="red">
            *
          </Box>
        </FormLabel>
        <Select
          {...register('outline.addr_country', {
            required: 'Please select a country',
          })}
          id="field-addr_country"
          placeholder="Select country"
        >
          {countries.map((country) => {
            return (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            );
          })}
        </Select>
        <FormErrorMessage>{errors.outline?.addr_country?.message}</FormErrorMessage>
      </FormControl>

      <Flex
        as="aside"
        fontSize="sm"
        mb="3"
        backgroundColor="blue.50"
        p="2"
        borderRadius="lg"
        alignItems="center"
      >
        <Box mr="3">
          <InfoOutlineIcon boxSize="1.1em" />
        </Box>
        <Box>
          Providing researched building height and floor values yields a more realistic building
          model and enables more accurate analysis
        </Box>
      </Flex>
      <FormControl isInvalid={!!errors.outline?.height} mb="3">
        <FormLabel>Building Height ({heightUnits === 'm' ? 'm' : 'ft'})</FormLabel>
        <Input
          {...register('outline.height', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            valueAsNumber: true,
            min: { value: 1, message: 'Please enter a height greater than zero' },
          })}
          id="field-height"
          type="number"
        />
        <FormErrorMessage>{errors.outline?.height?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.floors} mb="3">
        <FormLabel>Floors</FormLabel>
        <Input
          {...register('outline.floors', {
            valueAsNumber: true,
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            min: { value: 1, message: 'Please enter a number greater than zero' },
          })}
          type="number"
          id="field-floors"
        />
        <FormErrorMessage>{errors.outline?.floors?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.outline?.da_notes} mb="3">
        <FormLabel>Notes</FormLabel>
        <Input
          {...register('outline.da_notes', {
            maxLength: { value: 255, message: 'Maximum 255 characters' },
            pattern: { value: /^[^"]*$/, message: 'Please do not use double quotes' },
          })}
          id="field-da_notes"
        />
        <FormErrorMessage>{errors.outline?.da_notes?.message}</FormErrorMessage>
      </FormControl>
    </Fragment>
  );
};

export default BuildingEditOutlineSection;
