import { Box, Center, Flex, Grid } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLayersClient from '../../services/LayersClient';
import NodesModal from '../ActionsModal/NodesModal';
import AddressAnalysisModal from '../AddressAnalysisModal/AddressAnalysisModal';
import BuildingEditModal from '../BuildingEditModal/BuildingEditModal';
import ClusterLayer from '../ClusterLayer/ClusterLayer';
import ContextSelection from '../ContextSelection';
import DetailsDrawer from '../DetailsDrawer';
import ExportData from '../ExportData';
import Info from '../Info';
import LayersControls from '../LayersControls';
import MapAdditionalMenu from '../MapAdditionalMenu/MapAdditionalMenu';
import MapLayerSelect from '../MapLayerSelect';
import MapResetNorthControl from '../MapResetNorthControl';
import MapResetPitchControl from '../MapResetPitchControl';
import MapStreetViewButton from '../MapStreetView/MapStreetViewButton';
import StreetView from '../MapStreetView/StreetView';
import MapZoomControl from '../MapZoomControl';
import NavToApps from '../NavToApps/NavToApps';
import Navigation from '../Navigation';
import Profile from '../Profile';
import ScaleLegends from '../ScaleLegends';
import BottomCenter from './BottomCenter';
import BottomLeft from './BottomLeft';
import TopCenter from './TopCenter';
import TopLeft from './TopLeft';
import TopRight from './TopRight';

type MapUiOverlayProps = {};

const MapUiOverlay: FC<MapUiOverlayProps> = () => {
  const layersClient = useLayersClient();

  const streetViewInitialState = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewFirstLoad
  );

  const { data: layerConfig } = layersClient;

  return (
    <Grid
      height="100%"
      left={0}
      overflow="hidden"
      pointerEvents="none"
      position="absolute"
      templateColumns="repeat(12, 1fr)"
      templateRows="repeat(12, 1fr)"
      top={0}
      width="100%"
    >
      <TopLeft>
        <Flex direction={'column'} height="100%">
          <Box mb="2" flex="0 0">
            <Profile />
          </Box>
          <Flex
            direction="column"
            flex="1 1"
            wrap="wrap"
            gap="0.5rem"
            alignItems="flex-start"
            minHeight="0"
          >
            <NavToApps />
            <Navigation />
            <MapZoomControl />
            <MapResetNorthControl />
            <MapResetPitchControl />
            <MapLayerSelect />
            <MapStreetViewButton />
            <MapAdditionalMenu />
          </Flex>
        </Flex>
      </TopLeft>
      <BottomLeft>
        <ScaleLegends />
      </BottomLeft>
      <TopCenter>
        <Center>
          <ContextSelection />
        </Center>
      </TopCenter>
      <BottomCenter>
        <Center>
          <Info />
        </Center>
      </BottomCenter>
      <TopRight>
        <>
          <LayersControls />
          <ClusterLayer />
        </>
      </TopRight>
      <NodesModal />
      <ExportData />
      <AddressAnalysisModal />

      <BuildingEditModal />
      {streetViewInitialState ? <StreetView /> : null}
      {layerConfig
        ? layerConfig.map((config) => {
            const detailsConfig = config.details;

            if (detailsConfig && detailsConfig.length > 0) {
              if (config.fromApi) {
                return config.layerParts.map((layerPartConfig) => {
                  if (layerPartConfig.type === 'geojson' && layerPartConfig.layerStyles) {
                    return layerPartConfig.layerStyles.main.map((layerStyle) => {
                      return (
                        <Fragment key={`${config.layerId}-${layerStyle.type}-detail-drawer`}>
                          <DetailsDrawer
                            layerId={config.layerId}
                            drawerKey={`${config.layerId}`}
                            drawerConfig={detailsConfig}
                            layerIdData={config.layerIdData}
                          />
                          <DetailsDrawer
                            layerId={config.layerId}
                            drawerKey={`${config.layerId}-${layerStyle.type}-grey`}
                            drawerConfig={detailsConfig}
                          />
                        </Fragment>
                      );
                    });
                  }
                  return null;
                });
              } else {
                return (
                  <Fragment key={`${config.layerId}-drawers`}>
                    <DetailsDrawer
                      layerId={config.layerId}
                      key={`${config.layerId}-detail-drawer`}
                      drawerKey={config.layerId}
                      drawerConfig={detailsConfig}
                    />
                    <DetailsDrawer
                      layerId={config.layerId}
                      key={`${config.layerId}-text-detail-drawer`}
                      drawerKey={`${config.layerId}-text`}
                      drawerConfig={detailsConfig}
                    />
                    <DetailsDrawer
                      layerId={config.layerId}
                      key={`${config.layerId}-grey-detail-drawer`}
                      drawerKey={`${config.layerId}-grey`}
                      drawerConfig={detailsConfig}
                    />
                  </Fragment>
                );
              }
            }

            return null;
          })
        : null}
    </Grid>
  );
};

export default MapUiOverlay;
