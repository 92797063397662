import { createContext, FC, ReactNode, useContext, useState } from 'react';

type BoundariesKey = 'outline' | number;

export type BuildingEditState = {
  activePartNumber: BoundariesKey;
};

type BuildingEditActions = {
  setActivePart: (index: BoundariesKey) => void;
};

const BuildingEditContext = createContext<[BuildingEditState, BuildingEditActions | null]>([
  { activePartNumber: 'outline' },
  null,
]);

const useBuildingEditContext = () => {
  const context = useContext(BuildingEditContext);

  return context;
};

const BuildingEditContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const [activePartNumber, setActivePart] = useState<BoundariesKey>('outline');

  const values = { activePartNumber };
  const actions = { setActivePart };

  return (
    <BuildingEditContext.Provider value={[values, actions]}>
      {children}
    </BuildingEditContext.Provider>
  );
};

export { useBuildingEditContext, BuildingEditContextProvider };
