import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import booleanIntersects from '@turf/boolean-intersects';
import { centroid, polygon } from '@turf/turf';
import _get from 'lodash/get';
import { FC, Fragment, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import SwiperJS, { EffectCoverflow, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  connectionBackupColors,
  connectionColors,
  getConnectionTypeOrder,
} from '../../config/bandAnalysis';
import { isInternalUser } from '../../helpers/AuthProvider';
import { Logger } from '../../helpers/Logger';
import booleanContains from '../../helpers/booleanContains';
import { getFeatureId } from '../../helpers/getFeatureId';
import { DetailConfig } from '../../helpers/layerConfig.types';
import { formatValue } from '../../helpers/utils';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useIsNeutralHost } from '../../hooks/useIsNeutralHost';
import { closeDrawer, DrawerKey } from '../../reducers/drawersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import { clearSelectedFeatureId } from '../../reducers/mapSettings';
import { SelectedStreetViewBuildingData } from '../../reducers/streetViewSlice';
import useAdditionalBuildingIdClient from '../../services/additionalBuildingDataByIdClient';
import useBandCountClient from '../../services/bandCountClient';
import { MccMnc, usePLMNQueryClient } from '../../services/contextClient';
import { AllowedClient, useDynamicClient } from '../../services/dynamicClient';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import DetailsModal from '../DetailsModal';
import DetailsDrawerPieChart from './DetailsDrawerPieChart';
import './detailsDrawerStyles.scss';

type DetailsDrawerContentProps = {
  drawerConfig: DetailConfig[];
  drawerKey: DrawerKey;
  layerId: LayerKey;
  layerIdData?: string;
};

const ItemTitle: FC<{ itemTitle: string }> = ({ itemTitle }) => {
  return (
    <Heading
      size="xs"
      color="white"
      as="h3"
      fontWeight="normal"
      id={`${itemTitle}`}
      key={`${itemTitle}`}
    >
      <span id={`${itemTitle}`} dangerouslySetInnerHTML={{ __html: itemTitle }}></span>:
    </Heading>
  );
};

const SliderItem: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Flex
      className="slider-item"
      pointerEvents="auto"
      width="100%"
      height="100%"
      alignItems="flex-end"
    >
      <Box backgroundColor="brand.500" borderRadius="md" margin="2" padding="3">
        {children}
      </Box>
    </Flex>
  );
};

const DetailsDrawerContent: FC<DetailsDrawerContentProps> = (props) => {
  const { drawerConfig, drawerKey, layerId, layerIdData } = props;

  const dispatch = useAppDispatch();

  const selectedCountryCode = useAppSelector((state) => state.contextSettings.selectedCountryCode);
  const selectedProviderName = useAppSelector(
    (state) => state.contextSettings.selectedProviderName
  );
  const providerPlmns = usePLMNQueryClient({
    countryCode: selectedCountryCode,
    providerName: selectedProviderName,
  });
  const isNeutralHost = useIsNeutralHost();

  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);
  const south = viewport[0][0];
  const west = viewport[0][1];
  const north = viewport[1][0];
  const east = viewport[1][1];
  const viewportFeature = useMemo(
    () =>
      polygon([
        [
          [south, west],
          [north, west],
          [north, east],
          [south, east],
          [south, west],
        ],
      ]),
    [north, south, east, west]
  );

  const [feature, setFeature] = useState<GeoJSON.Feature | null | undefined>(undefined);

  const featureProperties = feature ? feature.properties : null;

  useEffect(() => {
    if (feature && feature.geometry.type !== 'GeometryCollection') {
      dispatch(SelectedStreetViewBuildingData(centroid(feature.geometry).geometry.coordinates));
    }
  }, [dispatch, feature]);

  const selectedFeatureId = useAppSelector((state) => state.mapSettings.selectedFeatureId);

  const client = useDynamicClient(drawerKey as AllowedClient);

  const responses = client({
    layerKey: layerId,
    providerName: selectedProviderName,
    countryCode: selectedCountryCode,
    plmns: providerPlmns.isSuccess ? providerPlmns.data : ([] as MccMnc[]),
    startUtc: new Date('2021-01-01'),
    endUtc: new Date('2100-01-01'),
    enabled: false,
    layerId: layerIdData ? layerIdData : layerId,
  });

  const responseDataFeatures = responses.flatMap((r: any) => {
    if (r.isSuccess && r.data) {
      return r.data;
    }

    return [];
  });

  useEffect(() => {
    if (responseDataFeatures.length > 0) {
      const feature: GeoJSON.Feature | undefined = responseDataFeatures.find(
        (f: GeoJSON.Feature) => {
          return getFeatureId(f, layerId) === selectedFeatureId;
        }
      );

      if (feature) {
        setFeature(feature);
      } else {
        setFeature(undefined);
      }
    }
  }, [responseDataFeatures, selectedFeatureId, layerId]);

  useEffect(() => {
    if (feature) {
      try {
        const isFeatureInViewport = booleanContains(viewportFeature, feature);
        const isFeatureIntersectingViewport = booleanIntersects(viewportFeature, feature);

        const isVisible = isFeatureInViewport || isFeatureIntersectingViewport;

        if (!isVisible) {
          dispatch(closeDrawer());
          dispatch(clearSelectedFeatureId());
        }
      } catch (e) {
        Logger.error('Error testing if feature in view', e);
      }
    }
  }, [feature, viewportFeature, dispatch, drawerKey]);

  const swiperRef = useRef<SwiperJS | null>(null);

  const gridDrawerKeys: (number | string)[] = ['grids', 'grids-grey'];

  const itemId = featureProperties
    ? gridDrawerKeys.includes(drawerKey)
      ? featureProperties.gridCellId ?? 0
      : featureProperties.buildingId ?? 0
    : 0;

  const { status: bandStatus, data: bandData } = useBandCountClient(
    itemId,
    featureProperties
      ? {
          binId: itemId,
          countryCode: selectedCountryCode,
          providerName: selectedProviderName,
          resultComplexity: 1,
          rssnrMetricRunId: featureProperties.rssnrMetricRunId || 0,
          type: drawerKey.toString(),
        }
      : null
  );

  const additionalBuildingData = useAdditionalBuildingIdClient({ buildingId: itemId });

  const topRef = useRef<HTMLDivElement | null>(null);

  if (featureProperties === null) {
    return (
      <Center padding="2" color="white">
        No information available
      </Center>
    );
  }

  if (featureProperties === undefined) {
    return null;
  }

  const indexOfBand = drawerConfig.findIndex((config) => {
    if (config.sectionKey === 'band-analysis') {
      return true;
    }
    return false;
  });

  const previousButtonClassname = 'dw-swiper-details-prev';
  const nextButtonClassname = 'dw-swiper-details-next';

  return (
    <>
      <Flex alignContent={'center'} justifyContent={'center'} justifyItems={'center'} ref={topRef}>
        <Box ml={'10px'} mr={'20px'}>
          {drawerConfig.map((section, i) => {
            if (section.navigationOptions) {
              const buttonsHeading = section.navigationOptions.navigationHeading;
              const isAfterBand = i > indexOfBand;
              const offset = bandData
                ? Object.entries(bandData.referenceAllBandsInUseByConnectionCategory).length
                : 0;
              const indexToSlideTo = isAfterBand ? i + offset : i;

              return (
                <Box
                  key={`${buttonsHeading}-slide-navigation-box`}
                  mb={'10px'}
                  onClick={() => {
                    if (swiperRef.current) {
                      swiperRef.current.slideToLoop(indexToSlideTo, 800);
                    }
                  }}
                >
                  <Button
                    id={`${buttonsHeading}-slide-navigation-button`}
                    key={`${buttonsHeading}-slide-navigation-button`}
                    width={'100%'}
                    _focus={{
                      bg: 'brand.900',
                    }}
                  >
                    {buttonsHeading}
                  </Button>
                </Box>
              );
            } else {
              return null;
            }
          })}

          {`${drawerKey}` === 'oss' && isInternalUser() ? (
            <Box display={'inline-block'}>
              <DetailsModal drawerKey={drawerKey} />
            </Box>
          ) : null}
        </Box>

        {isNeutralHost ? (
          <Swiper
            margin-left={0}
            margin-right={0}
            centeredSlides={false}
            slidesPerView={2}
            onSwiper={(swiperInstance) => {
              swiperRef.current = swiperInstance;
            }}
          >
            {drawerConfig.map((section): ReactElement => {
              return (
                <SwiperSlide key={`nh-${section.sectionKey}`}>
                  {
                    section.items.map((detailItem) => {
                      if (detailItem.type === 'grid') {
                        return (
                          <SliderItem key={`drawer-section-sliderItem-${detailItem.itemKey}`}>
                            <Box
                              color="white"
                              key={`drawer-section-detail-${detailItem.itemKey}`}
                              id={`drawer-section-detail-${detailItem.itemKey}`}
                            >
                              <Grid
                                templateColumns={'repeat(2, 1fr)'}
                                gridGap={'10px'}
                                maxWidth={'500px'}
                              >
                                {detailItem.rows.map((row) => {
                                  const value = featureProperties[row.metricName] ?? 'N/A';
                                  const decimalPlaces = row.decimalPlaces ?? 0;

                                  const colorPropName = row.accessor
                                    ? `${row.accessor}Color`
                                    : `${row.metricName}Color`;
                                  const color = _get(featureProperties, colorPropName);

                                  const size = '1em';
                                  const description = row.description;
                                  return (
                                    <Grid
                                      key={row.rowKey}
                                      gridGap={'5px'}
                                      templateColumns={'0.5fr 2fr 1fr'}
                                      borderBottom={'1px solid white'}
                                    >
                                      {description ? (
                                        <Popover trigger="hover">
                                          <PopoverTrigger>
                                            <Icon name="help" color="white" ml="2" />
                                          </PopoverTrigger>
                                          <Portal containerRef={topRef}>
                                            <PopoverContent width="md">
                                              <PopoverBody whiteSpace="pre-line">
                                                <Box as="span" fontSize="sm">
                                                  <Text color={'black'}>{`${description}`}</Text>
                                                  {row.descriptionImageUrl ? (
                                                    <Image src={row.descriptionImageUrl} mt="3" />
                                                  ) : null}
                                                </Box>
                                              </PopoverBody>
                                            </PopoverContent>
                                          </Portal>
                                        </Popover>
                                      ) : (
                                        <Box></Box>
                                      )}
                                      <Box
                                        fontSize="xs"
                                        id={`${row.rowText}`}
                                        key={`${row.rowText}`}
                                        dangerouslySetInnerHTML={{ __html: row.rowText }}
                                        display={'inline-block'}
                                      ></Box>

                                      <Box fontSize="xs">
                                        <Flex width="100%" justifyContent="space-between">
                                          <Flex>
                                            {formatValue(value, row.format, { decimalPlaces })}
                                            {row.enableCopy ? (
                                              <Box ml="10px">
                                                <CopyToClipboard copyText={`${value}`} />
                                              </Box>
                                            ) : null}
                                          </Flex>

                                          {row.showColor && color ? (
                                            <Box
                                              ml="3"
                                              width={size}
                                              height={size}
                                              backgroundColor={color.toString()}
                                            />
                                          ) : null}
                                        </Flex>
                                      </Box>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Box>
                          </SliderItem>
                        );
                      }
                      if (detailItem.type === 'table') {
                        const propertyValue = featureProperties[detailItem.header.metricName];
                        const headers = Array.isArray(propertyValue)
                          ? propertyValue
                          : [propertyValue];

                        return (
                          <SliderItem key={`drawer-section-table-${detailItem.itemKey}`}>
                            <Box
                              color="white"
                              key={`drawer-section-detail-${detailItem.itemKey}`}
                              id={`drawer-section-detail-${detailItem.itemKey}`}
                            >
                              <Table>
                                <Thead>
                                  <Tr>
                                    <Th padding={0} />
                                    <Th padding={0} />
                                    <Th
                                      fontSize="xs"
                                      color="white"
                                      textAlign="center"
                                      colSpan={headers.length}
                                      id={`${detailItem.header.headerLabel}`}
                                    >
                                      {detailItem.header.headerLabel}
                                    </Th>
                                  </Tr>
                                  <Tr>
                                    <Th padding={0} />
                                    <Th padding={0} />
                                    {headers.map((header) => {
                                      return (
                                        <Th
                                          id={`${header}`}
                                          key={`${header}`}
                                          fontSize="xs"
                                          color="white"
                                        >
                                          {header}
                                        </Th>
                                      );
                                    })}
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {detailItem.rows.map((row) => {
                                    const propertyValue = featureProperties[row.metricName];
                                    const values = Array.isArray(propertyValue)
                                      ? propertyValue
                                      : [propertyValue];

                                    return (
                                      <Tr key={`${row.metricName}`}>
                                        <Td padding={0}>
                                          {row.description ? (
                                            <Popover trigger="hover">
                                              <PopoverTrigger>
                                                <Icon name="help" color="white" />
                                              </PopoverTrigger>
                                              <Portal containerRef={topRef}>
                                                <PopoverContent width="md">
                                                  <PopoverBody whiteSpace="pre-line">
                                                    <Box as="span" fontSize="sm">
                                                      <Text
                                                        color={'black'}
                                                      >{`${row.description}`}</Text>
                                                      {row.descriptionImageUrl ? (
                                                        <Image
                                                          src={row.descriptionImageUrl}
                                                          mt="3"
                                                        />
                                                      ) : null}
                                                    </Box>
                                                  </PopoverBody>
                                                </PopoverContent>
                                              </Portal>
                                            </Popover>
                                          ) : (
                                            <Box></Box>
                                          )}
                                        </Td>
                                        <Td
                                          id={`${row.rowText}`}
                                          key={`${row.rowText}`}
                                          paddingLeft={2}
                                          fontSize="xs"
                                        >
                                          {row.rowText}
                                        </Td>
                                        {values.map((value, i) => {
                                          return (
                                            <Td
                                              key={`value-cell-${i}`}
                                              fontSize="xs"
                                              textAlign="right"
                                            >
                                              {value !== null && value !== undefined
                                                ? formatValue(value, row.format, {
                                                    decimalPlaces: row.decimalPlaces,
                                                  })
                                                : null}
                                            </Td>
                                          );
                                        })}
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </Table>
                            </Box>
                          </SliderItem>
                        );
                      }
                      return null;
                    }) // map section
                  }
                </SwiperSlide>
              );
            })}
            {/* /* ///drawer config map close */}
          </Swiper>
        ) : (
          <>
            {drawerConfig.length > 1 ? (
              <Box
                className={previousButtonClassname}
                display={'flex'}
                alignItems={'center'}
                marginRight={'10px'}
              >
                <Box
                  cursor="pointer"
                  background={'brand.600'}
                  borderRadius={'3px'}
                  padding={'30px 0px'}
                >
                  <BsChevronCompactLeft size="3em" color="white" />
                </Box>
              </Box>
            ) : null}

            <Swiper
              key={'swiper'}
              initialSlide={1}
              effect={'coverflow'}
              grabCursor={true}
              navigation={{
                prevEl: `.${previousButtonClassname}`,
                nextEl: `.${nextButtonClassname}`,
              }}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              modules={[Navigation, EffectCoverflow]}
              onSwiper={(swiperInstance) => {
                swiperRef.current = swiperInstance;
              }}
            >
              {/* NOTE: The slide width is overridden in App.scss! */}

              {drawerConfig.map((section): ReactElement => {
                let countOfMissingColorsAllBands = 0;
                let countOfMissingColorsCategory = 0;

                if (section.sectionKey === 'band-analysis') {
                  return (
                    <Fragment key={`${section.sectionKey}-fragment`}>
                      <SwiperSlide
                        key={`${section.sectionKey}`}
                        id="all-bands-analysis-slider-item"
                      >
                        <SliderItem>
                          <Heading size="sm" color="white" as="h2">
                            All Bands Analysis
                          </Heading>
                          <Box padding="2" userSelect="text">
                            {bandStatus === 'loading' ? (
                              <Flex justifyContent={'center'} pt={1}>
                                <Spinner color="white" />
                              </Flex>
                            ) : null}
                            <Box>
                              {bandData ? (
                                <DetailsDrawerPieChart
                                  tableId="all-bands-analysis-slider-table"
                                  config={bandData.referenceAllBandsInUse.map((band) => {
                                    const colorFromLookup = connectionColors[band];
                                    let colorToUse = colorFromLookup;

                                    if (colorFromLookup === undefined) {
                                      colorToUse =
                                        connectionBackupColors[countOfMissingColorsAllBands];
                                      countOfMissingColorsAllBands++;
                                    }

                                    return {
                                      label: band,
                                      metricName: band,
                                      color: colorToUse,
                                    };
                                  })}
                                  properties={bandData.byBand}
                                  hidePieIfZero
                                />
                              ) : null}
                            </Box>
                          </Box>
                        </SliderItem>
                      </SwiperSlide>

                      {bandData &&
                      Object.entries(bandData.referenceAllBandsInUseByConnectionCategory).length > 0
                        ? Object.entries(bandData.referenceAllBandsInUseByConnectionCategory)
                            .sort((a, b) => {
                              const aOrder = getConnectionTypeOrder(a[0]);
                              const bOrder = getConnectionTypeOrder(b[0]);
                              return aOrder - bOrder;
                            })
                            .map(([connectionCategory, connectionCategoryCounts]) => {
                              const connectionCategoryText =
                                connectionCategory === '5G'
                                  ? '5G SA'
                                  : connectionCategory === '5G-NSA'
                                  ? '5G NSA'
                                  : connectionCategory;

                              return (
                                <SwiperSlide
                                  id={`${connectionCategory}-bands-analysis-slider-item`}
                                  key={`${selectedFeatureId}-${connectionCategory}-bands-analysis-slider-item`}
                                >
                                  <SliderItem key={`${connectionCategory}-bands-analysis-slider`}>
                                    <Heading size="sm" color="white" as="h2">
                                      {connectionCategoryText} Band Analysis
                                    </Heading>
                                    <Box padding="2" userSelect="text">
                                      <Box>
                                        <DetailsDrawerPieChart
                                          tableId={`${connectionCategory}-bands-analysis-slider-table`}
                                          config={connectionCategoryCounts.map((band) => {
                                            const colorFromLookup = connectionColors[band];
                                            let colorToUse = colorFromLookup;

                                            if (colorFromLookup === undefined) {
                                              colorToUse =
                                                connectionBackupColors[
                                                  countOfMissingColorsCategory
                                                ];
                                              countOfMissingColorsCategory++;
                                            }

                                            return {
                                              label: band,
                                              metricName: band,
                                              color: colorToUse,
                                            };
                                          })}
                                          properties={
                                            bandData.byConnectionCategory[connectionCategory] ?? {}
                                          }
                                          hidePieIfZero
                                        />
                                      </Box>
                                    </Box>
                                  </SliderItem>
                                </SwiperSlide>
                              );
                            })
                        : null}
                    </Fragment>
                  );
                } else if (section.sectionKey === 'additional-information') {
                  return (
                    <Fragment key={`${section.sectionKey}-fragment`}>
                      <SwiperSlide key={`${section.sectionKey}`} id="additional-information">
                        <SliderItem>
                          <Card
                            maxH={'300px'}
                            overflow={'hidden'}
                            overflowY={'auto'}
                            bg={'brand.500'}
                            shadow={'none'}
                          >
                            <Heading size="md" color="white" as="h2">
                              Additional Information
                            </Heading>
                            <Box padding="2" userSelect="text">
                              {additionalBuildingData.isLoading === true ? (
                                <Flex justifyContent={'center'} pt={1}>
                                  <Spinner color="white" />
                                </Flex>
                              ) : additionalBuildingData.isError === true ? (
                                <Box display={'grid'} justifyContent={'center'}>
                                  <Text size="sm" color="white">
                                    Error Loading Data
                                  </Text>
                                  <Button
                                    id="button-retry-fetching-additional-info"
                                    onClick={() => additionalBuildingData.refetch()}
                                    bgColor={'primaryLightFifteenPercent'}
                                  >
                                    Retry
                                  </Button>
                                </Box>
                              ) : null}
                              <Box>
                                {additionalBuildingData.data &&
                                Object.entries(additionalBuildingData.data.tags).length > 0 ? (
                                  <Grid
                                    key={`drawer-section-detail-grid-big-grid`}
                                    templateColumns={'repeat(2, 1fr)'}
                                    gridGap={'10px'}
                                    color="white"
                                    maxWidth={'500px'}
                                  >
                                    {Object.entries(additionalBuildingData.data.tags).map(
                                      (tag: any) => {
                                        const [key, value] = tag;
                                        return (
                                          <Grid
                                            key={key}
                                            id={`${key}`}
                                            gridGap={'5px'}
                                            templateColumns={'1fr 1fr'}
                                            borderBottom={'1px solid white'}
                                          >
                                            <Box
                                              fontSize="sm"
                                              id={`${key}-key`}
                                              key={`${key}`}
                                              display={'inline-block'}
                                            >
                                              {key}
                                            </Box>
                                            <Box id={`${value}-value`} fontSize="sm">
                                              {value}
                                            </Box>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (additionalBuildingData.data &&
                                    Object.entries(additionalBuildingData.data.tags).length ===
                                      0) ||
                                  additionalBuildingData.data === null ? (
                                  <Text size="sm" color="white">
                                    Additional data not available for the buildling
                                  </Text>
                                ) : null}
                              </Box>
                            </Box>
                          </Card>
                        </SliderItem>
                      </SwiperSlide>
                    </Fragment>
                  );
                } else {
                  return (
                    <SwiperSlide
                      key={`${section.sectionKey}-slider-item`}
                      id={`${section.sectionKey}-slider-item`}
                    >
                      <SliderItem key={`${section.sectionKey}-slider-item`}>
                        <Heading size="sm" color="white" as="h2" pb={'10px'} pl={'10px'}>
                          {section.sectionTitle}
                        </Heading>
                        <Box padding="2" userSelect="text">
                          {section.items.map((detailItem) => {
                            if (detailItem.type === 'row') {
                              const value = featureProperties[detailItem.metricName] ?? 'N/A';
                              const decimalPlaces = detailItem.decimalPlaces ?? 0;
                              return (
                                <Flex
                                  key={`drawer-section-detail-${detailItem.itemKey}`}
                                  justifyContent="center"
                                  id={`drawer-section-detail-${detailItem.itemKey}`}
                                >
                                  <Flex
                                    key={`drawer-section-row-${detailItem.itemKey}`}
                                    width="50%"
                                    flexGrow="0"
                                    flexShrink="0"
                                    justify="flex-end"
                                    mr="1"
                                  >
                                    {detailItem.itemTitle ? (
                                      <ItemTitle itemTitle={detailItem.itemTitle} />
                                    ) : null}
                                  </Flex>
                                  <Text
                                    fontSize="xs"
                                    ml="1"
                                    color="white"
                                    width="50%"
                                    flexGrow="0"
                                    flexShrink="0"
                                  >
                                    {formatValue(value, detailItem.format, { decimalPlaces })}
                                  </Text>
                                </Flex>
                              );
                            }
                            if (detailItem.type === 'pieChart') {
                              return (
                                <Box
                                  key={`drawer-section-detail-${detailItem.itemKey}`}
                                  id={`drawer-section-detail-${detailItem.itemKey}`}
                                >
                                  {detailItem.itemTitle ? (
                                    <ItemTitle itemTitle={detailItem.itemTitle} />
                                  ) : null}
                                  <DetailsDrawerPieChart
                                    tableId={`${section.sectionKey}-slider-table`}
                                    config={detailItem.series}
                                    properties={featureProperties}
                                  />
                                </Box>
                              );
                            }
                            if (detailItem.type === 'table') {
                              const propertyValue = featureProperties[detailItem.header.metricName];
                              const headers = Array.isArray(propertyValue)
                                ? propertyValue
                                : [propertyValue];

                              return (
                                <Box
                                  color="white"
                                  key={`drawer-section-detail-${detailItem.itemKey}`}
                                  id={`drawer-section-detail-${detailItem.itemKey}`}
                                >
                                  <Table>
                                    <Thead>
                                      <Tr>
                                        <Th
                                          key={`drawer-section-table-heading-${detailItem.itemKey}-1`}
                                          padding={0}
                                        />
                                        <Th
                                          key={`drawer-section-table-heading-${detailItem.itemKey}-2`}
                                          padding={0}
                                        />
                                        <Th
                                          fontSize="xs"
                                          color="white"
                                          textAlign="center"
                                          colSpan={headers.length}
                                          id={detailItem.header.headerLabel}
                                          key={`drawer-section-detail-${detailItem.itemKey}-3`}
                                        >
                                          {detailItem.header.headerLabel}
                                        </Th>
                                      </Tr>
                                      <Tr>
                                        <Th padding={0} />
                                        <Th padding={0} />
                                        {headers.map((header) => {
                                          return (
                                            <Th
                                              id={`${header}`}
                                              key={`table-header-${header?.toString()}`}
                                              fontSize="xs"
                                              color="white"
                                            >
                                              {header}
                                            </Th>
                                          );
                                        })}
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {detailItem.rows.map((row) => {
                                        const propertyValue = featureProperties[row.metricName];
                                        const values = Array.isArray(propertyValue)
                                          ? propertyValue
                                          : [propertyValue];

                                        return (
                                          <Tr key={`${row.rowText}`}>
                                            <Td padding={0}>
                                              {row.description ? (
                                                <Popover trigger="hover">
                                                  <PopoverTrigger
                                                    key={`hover-trigger-${row.description}`}
                                                  >
                                                    <Icon name="help" color="white" />
                                                  </PopoverTrigger>
                                                  <Portal containerRef={topRef}>
                                                    <PopoverContent width="md">
                                                      <PopoverBody whiteSpace="pre-line">
                                                        <Box as="span" fontSize="sm">
                                                          <Text
                                                            color={'black'}
                                                          >{`${row.description}`}</Text>
                                                          {row.descriptionImageUrl ? (
                                                            <Image
                                                              src={row.descriptionImageUrl}
                                                              mt="3"
                                                            />
                                                          ) : null}
                                                        </Box>
                                                      </PopoverBody>
                                                    </PopoverContent>
                                                  </Portal>
                                                </Popover>
                                              ) : (
                                                <Box></Box>
                                              )}
                                            </Td>
                                            <Td paddingLeft={2} fontSize="xs">
                                              {row.rowText}
                                            </Td>
                                            {values.map((value) => {
                                              return (
                                                <Td
                                                  fontSize="xs"
                                                  textAlign="right"
                                                  key={`${value?.toString()}`}
                                                >
                                                  {value !== null && value !== undefined
                                                    ? formatValue(value, row.format, {
                                                        decimalPlaces: row.decimalPlaces,
                                                      })
                                                    : null}
                                                </Td>
                                              );
                                            })}
                                          </Tr>
                                        );
                                      })}
                                    </Tbody>
                                  </Table>
                                </Box>
                              );
                            }
                            if (detailItem.type === 'grid') {
                              return (
                                <Box
                                  color="white"
                                  key={`drawer-section-detail-${detailItem.itemKey}`}
                                  id={`drawer-section-detail-${detailItem.itemKey}`}
                                >
                                  <Grid
                                    key={`drawer-section-detail-grid-${detailItem.itemKey}`}
                                    templateColumns={'repeat(2, 1fr)'}
                                    gridGap={'10px'}
                                    maxWidth={'500px'}
                                  >
                                    {detailItem.rows.map((row) => {
                                      const value = row.accessor
                                        ? _get(featureProperties, row.accessor)
                                        : featureProperties[row.metricName] ?? 'N/A';
                                      const decimalPlaces = row.decimalPlaces ?? 0;

                                      const colorPropName = row.accessor
                                        ? `${row.accessor}Color`
                                        : `${row.metricName}Color`;
                                      const color = _get(featureProperties, colorPropName);

                                      const size = '1em';
                                      const description = row.description;
                                      return (
                                        <Grid
                                          key={row.rowKey}
                                          gridGap={'5px'}
                                          templateColumns={'0.5fr 2fr 1fr'}
                                          borderBottom={'1px solid white'}
                                        >
                                          {description ? (
                                            <Popover trigger="hover">
                                              <PopoverTrigger
                                                key={`hover-trigger-${row.description}`}
                                              >
                                                <Icon name="help" color="white" ml="2" />
                                              </PopoverTrigger>
                                              <Portal containerRef={topRef}>
                                                <PopoverContent width="md">
                                                  <PopoverBody whiteSpace="pre-line">
                                                    <Box as="span" fontSize="sm">
                                                      <Text
                                                        color={'black'}
                                                      >{`${description}`}</Text>
                                                      {row.descriptionImageUrl ? (
                                                        <Image
                                                          src={row.descriptionImageUrl}
                                                          mt="3"
                                                        />
                                                      ) : null}
                                                    </Box>
                                                  </PopoverBody>
                                                </PopoverContent>
                                              </Portal>
                                            </Popover>
                                          ) : (
                                            <Box key={row.metricName}></Box>
                                          )}
                                          <Box
                                            fontSize="xs"
                                            id={`${row.rowText}`}
                                            key={`${row.rowText}`}
                                            dangerouslySetInnerHTML={{ __html: row.rowText }}
                                            display={'inline-block'}
                                          ></Box>

                                          <Box fontSize="xs">
                                            <Flex width="100%" justifyContent="space-between">
                                              <Flex>
                                                {formatValue(value, row.format, { decimalPlaces })}
                                                {row.enableCopy ? (
                                                  <Box ml="10px">
                                                    <CopyToClipboard copyText={`${value}`} />
                                                  </Box>
                                                ) : null}
                                              </Flex>
                                              {row.showColor && color ? (
                                                <Box
                                                  ml="3"
                                                  width={size}
                                                  height={size}
                                                  backgroundColor={color.toString()}
                                                />
                                              ) : null}
                                            </Flex>
                                          </Box>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </Box>
                              );
                            }
                            if (detailItem.type === 'html') {
                              return detailItem.rows.map((row) => {
                                const value = featureProperties[row.metricName] ?? 'N/A';
                                return (
                                  <Box
                                    key={`${row.metricName}`}
                                    className="dw-detail-html"
                                    dangerouslySetInnerHTML={{ __html: value.toString() }}
                                  ></Box>
                                );
                              });
                            }
                            return null;
                          })}
                        </Box>
                      </SliderItem>
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
            {drawerConfig.length > 1 ? (
              <Box
                className={nextButtonClassname}
                display={'flex'}
                alignItems={'center'}
                marginLeft={'10px'}
              >
                <Box
                  cursor="pointer"
                  background={'brand.600'}
                  borderRadius={'3px'}
                  padding={'30px 0px'}
                >
                  <BsChevronCompactRight size="3em" color="white" />
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </Flex>
    </>
  );
};

export default DetailsDrawerContent;
