import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import MapboxDraw, { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import {
  feature,
  FeatureCollection,
  featureCollection,
  kinks,
  LineString,
  Point,
  Polygon,
} from '@turf/turf';
import { MapMouseEvent } from 'mapbox-gl';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BsDashLg, BsLayersHalf, BsPlusLg } from 'react-icons/bs';
import { FaRegTrashCan } from 'react-icons/fa6';
import { TbShapeOff } from 'react-icons/tb';
import { Layer, Map, MapRef, Source, useMap, ViewState } from 'react-map-gl';
import { GeoJSONPolygon, parse, stringify as wktStringify } from 'wellknown';
import { getBBox2d } from '../../helpers/MapHelpers';
import { useAppSelector } from '../../hooks/useAppSelector';
import useChunkArea from '../../hooks/useChunkArea';
import { fetchGenericGeo, GenericResultResponse } from '../../services/genericClient';
import MapOverlayButton from '../MapOverlayButton';
import { useBuildingEditContext } from './BuildingEditContext';
import { BuildingFormState } from './BuildingEditForm';

const isAllowedShape = (
  shape: GeoJSON.Feature
): shape is GeoJSON.Feature<
  GeoJSON.LineString | GeoJSON.MultiLineString | GeoJSON.Polygon | GeoJSON.MultiPolygon
> => {
  return ['LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'].includes(shape.geometry.type);
};

const useDrawMode = (map: MapRef | undefined) => {
  const [{ activePartNumber }, buildingEditContextActions] = useBuildingEditContext();
  const { setValue, setError, clearErrors, getValues } = useFormContext<BuildingFormState>();

  const { current: draw } = useRef(
    new MapboxDraw({
      defaultMode: 'draw_polygon',
      userProperties: true,
      controls: {
        point: false,
        combine_features: false,
        line_string: false,
        polygon: false,
        trash: false,
        uncombine_features: false,
      },
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ffae35',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          paint: {
            'fill-color': '#ffae35',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-active',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'true'],
            ['==', '$type', 'Polygon'],
            ['==', 'mode', 'simple_select'],
          ],
          paint: {
            'fill-color': '#0000ff',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-active',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'true'],
            ['==', '$type', 'Polygon'],
            ['==', 'mode', 'direct_select'],
          ],
          paint: {
            'fill-color': '#00ff00',
            'fill-outline-color': '#ffae35',
            'fill-opacity': 0.3,
          },
        },
        // polygon mid points halos
        {
          id: 'gl-draw-polygon-midpoint-halos',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 5,
            'circle-color': '#FFF',
          },
        },
        // polygon mid points
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 4,
            'circle-color': '#ffae35',
          },
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ffae35',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        // vertex point halos
        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 7,
            'circle-color': '#FFF',
          },
        },
        // vertex points
        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#ffae35',
          },
        },
        // Active Vertexes
        {
          id: 'gl-draw-polygon-and-line-vertex-selected',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#0000ff',
          },
        },
        // Style for invalid shape
        {
          id: 'gl-draw-line-invalid',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_invalid']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ff0000',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
        {
          id: 'gl-draw-polygon-fill-invalid',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_invalid']],
          paint: {
            'fill-color': '#ff0000',
            'fill-outline-color': '#ff0000',
            'fill-opacity': 0.3,
          },
        },
        {
          id: 'gl-draw-polygon-stroke-active-invalid',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_invalid']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#ff0000',
            'line-dasharray': [1, 3],
            'line-width': 3,
          },
        },
      ],
    })
  );

  const onFeatureChange = useCallback(
    (feature: GeoJSON.Feature) => {
      if (isAllowedShape(feature)) {
        const kinksCollection = kinks(feature);
        const numberOfKinks = kinksCollection.features.length;
        const isKinked = numberOfKinks > 0;

        // If it has kinks mark it invalid
        if (isKinked && feature.id) {
          draw.setFeatureProperty(feature.id.toString(), 'invalid', true);
        }

        // If it has kinks set the boundary error
        if (buildingEditContextActions && isKinked) {
          const fieldName =
            activePartNumber === 'outline'
              ? 'outline.boundary'
              : (`parts.${activePartNumber}.boundaryWkt` as const);
          setError(fieldName, { message: 'Building shape cannot cross itself' });
        }

        // If it's not kinked then clear invalid
        if (!isKinked && feature.id) {
          draw.setFeatureProperty(feature.id.toString(), 'invalid', undefined);
        }
        if (!isKinked && feature.id && buildingEditContextActions) {
          const fieldName =
            activePartNumber === 'outline'
              ? 'outline.boundary'
              : (`parts.${activePartNumber}.boundaryWkt` as const);
          clearErrors(fieldName);
        }

        if (buildingEditContextActions) {
          const wkt = wktStringify(feature.geometry as GeoJSONPolygon);
          const fieldName =
            activePartNumber === 'outline'
              ? 'outline.boundary'
              : (`parts.${activePartNumber}.boundaryWkt` as const);
          setValue(fieldName, wkt);
        }
      }
    },
    [draw, buildingEditContextActions, activePartNumber, clearErrors, setError, setValue]
  );

  const onCreate = useCallback(
    (e: DrawCreateEvent) => {
      const feature = e.features[0];
      onFeatureChange(feature);
    },
    [onFeatureChange]
  );

  const onUpdate = useCallback(
    (e: DrawUpdateEvent) => {
      const feature = e.features[0];
      onFeatureChange(feature);
    },
    [onFeatureChange]
  );

  const onDelete = useCallback(() => {
    draw.changeMode('draw_polygon');
    if (buildingEditContextActions) {
      const fieldName =
        activePartNumber === 'outline'
          ? 'outline.boundary'
          : (`parts.${activePartNumber}.boundaryWkt` as const);
      setValue(fieldName, '');
    }
  }, [draw, buildingEditContextActions, activePartNumber, setValue]);

  const onClearAll = useCallback(() => {
    draw.changeMode('draw_polygon');
    draw.deleteAll();
  }, [draw]);

  const onModechange = useCallback(() => {
    const allFeatures = draw.getAll();

    if (allFeatures.features.length === 0) {
      draw.changeMode('draw_polygon');
    }
  }, [draw]);

  useEffect(() => {
    const fieldName =
      activePartNumber === 'outline'
        ? 'outline.boundary'
        : (`parts.${activePartNumber}.boundaryWkt` as const);
    const boundary = getValues(fieldName);

    const boundaryAsFeature = boundary ? parse(boundary) : null;
    if (boundaryAsFeature) {
      draw.set({
        type: 'FeatureCollection',
        features: [
          { type: 'Feature', geometry: boundaryAsFeature, id: 'the_only_one', properties: {} },
        ],
      });
      draw.changeMode('direct_select', { featureId: 'the_only_one' });
    }
  }, [draw, getValues, activePartNumber]);

  useEffect(() => {
    if (map) {
      map.on('draw.create', onCreate);
      map.on('draw.update', onUpdate);
      map.on('draw.delete', onDelete);
      map.on('draw.modechange', onModechange);
      // clearAll is a custom event. Made up by us
      map.on('draw.clearAll', onClearAll);
    }

    return () => {
      if (map) {
        map.off('draw.create', onCreate);
        map.off('draw.update', onUpdate);
        map.off('draw.delete', onDelete);
        map.off('draw.modechange', onModechange);
        map.off('draw.clearAll', onClearAll);
      }
    };
  }, [map, onCreate, onUpdate, onDelete, onClearAll, onModechange]);

  useEffect(() => {
    if (map) {
      map.addControl(draw);
    }

    return () => {
      map?.removeControl(draw);
    };
  }, [map, draw]);

  return draw;
};

const BuildingEditMap: FC = () => {
  const { 'building-edit-map': editMap, 'building-edit-mini-map': miniMap } = useMap();
  const draw = useDrawMode(editMap);

  const mainMapViewState = useAppSelector((state) => state.mapSettings.viewState);
  const providerName = useAppSelector((s) => s.contextSettings.selectedProviderName);
  const countryCode = useAppSelector((s) => s.contextSettings.selectedCountryCode);

  const [buildingEditContextValues, buildingEditContextActions] = useBuildingEditContext();

  const { watch } = useFormContext<BuildingFormState>();
  const partFields = watch('parts');
  const outlineField = watch('outline');

  const buildingEditGeoJson = partFields.reduce(
    (acc, part, i) => {
      if (part.boundaryWkt) {
        const geoJson = parse(part.boundaryWkt);
        if (geoJson && geoJson.type === 'Polygon') {
          acc.features.push({
            type: 'Feature',
            geometry: geoJson,
            properties: {
              height: part.heightMetres,
              baseHeight: part.heightLowMetres,
              z: i,
              index: i % 2,
            },
          });
        }
      }

      return acc;
    },
    { type: 'FeatureCollection', features: [] } as FeatureCollection<Polygon>
  );

  const outlineGeometry = outlineField.boundary ? parse(outlineField.boundary) : null;
  const outlineGeoJson = outlineGeometry ? featureCollection([feature(outlineGeometry)]) : '';

  const [mapStyleUrl, setMapStyleUrl] = useState('mapbox://styles/mapbox/satellite-streets-v11');

  const [viewState, setViewState] = useState<ViewState>(mainMapViewState);
  const [viewportBounds, setViewportBounds] = useState<number[][] | null>(
    editMap ? editMap.getBounds().toArray() : null
  );

  useEffect(() => {
    if (editMap) {
      setViewportBounds(editMap.getBounds().toArray());
    }
  }, [editMap]);

  useEffect(() => {
    if (editMap) {
      const onClick = (e: MapMouseEvent) => {
        if (draw.getMode() === 'draw_polygon') {
          return;
        }
        const features = editMap.queryRenderedFeatures(e.point).filter((feature) => {
          return ['building-parts'].includes(feature.source);
        });
        if (features && features.length > 0) {
          const sortedIndexes = features.map((f) => f.properties?.z).sort();
          const topFeature = features[0];

          if (buildingEditContextValues.activePartNumber === null) {
            buildingEditContextActions?.setActivePart(topFeature.properties?.z);
          } else {
            const nextIndex = sortedIndexes.indexOf(buildingEditContextValues.activePartNumber) + 1;
            const nextIndexToSelect =
              nextIndex === sortedIndexes.length ? sortedIndexes[0] : sortedIndexes[nextIndex];
            buildingEditContextActions?.setActivePart(nextIndexToSelect);
          }
        }
      };

      editMap.on('click', onClick);

      return () => {
        editMap.off('click', onClick);
      };
    }
  }, [editMap, buildingEditContextValues.activePartNumber, buildingEditContextActions, draw]);

  useEffect(() => {
    if (miniMap) {
      const onClick = (e: MapMouseEvent) => {
        const features = miniMap.queryRenderedFeatures(e.point).filter((feature) => {
          return ['building-parts'].includes(feature.source);
        });
        if (features && features.length > 0) {
          const sortedIndexes = features.map((f) => f.properties?.z).sort();
          const topFeature = features[0];

          if (buildingEditContextValues.activePartNumber === null) {
            buildingEditContextActions?.setActivePart(topFeature.properties?.z);
          } else {
            const nextIndex = sortedIndexes.indexOf(buildingEditContextValues.activePartNumber) + 1;
            const nextIndexToSelect =
              nextIndex === sortedIndexes.length ? sortedIndexes[0] : sortedIndexes[nextIndex];
            buildingEditContextActions?.setActivePart(nextIndexToSelect);
          }
        }
      };

      miniMap.on('click', onClick);

      return () => {
        miniMap.off('click', onClick);
      };
    }
  }, [miniMap, buildingEditContextValues.activePartNumber, buildingEditContextActions]);

  const isMoving = editMap ? editMap.isMoving() : true;

  const { extents } = useChunkArea(
    isMoving ? null : viewportBounds,
    15,
    14 <= (editMap ? editMap.getZoom() : 20)
  );

  const buildingOutlineResponses = useQueries({
    queries: extents
      ? extents.map((chunk) => {
          const newChunk = getBBox2d(chunk);
          const [west, south, east, north] = newChunk;

          const query: QueryObserverOptions<GenericResultResponse | null> = {
            queryKey: ['-1', countryCode, providerName, newChunk.join('|')],
            queryFn: (queryFnOptions) => {
              const { signal } = queryFnOptions;

              return fetchGenericGeo(
                {
                  west,
                  south,
                  east,
                  north,
                  countryCode,
                  providerName,
                  layerId: '-1',
                },
                { signal }
              );
            },
            staleTime: 1000 * 60 * 5,
          };

          return query;
        })
      : [],
  });

  const buildingOutlineFeatures = buildingOutlineResponses.reduce(
    (acc: GenericResultResponse, r) => {
      if (r.isSuccess && r.data) {
        acc = [...acc, ...r.data];
      }

      return acc;
    },
    []
  );

  const buildingOutlineData = featureCollection<Polygon | Point | LineString>(
    buildingOutlineFeatures
  );

  return (
    <Box position="relative">
      <Map
        id="building-edit-map"
        reuseMaps
        onMoveEnd={(e) => {
          setViewState(e.viewState);
          setViewportBounds(e.target.getBounds().toArray());
        }}
        customAttribution="denseWare | &copy; Dense Air"
        initialViewState={mainMapViewState}
        mapStyle={mapStyleUrl}
        mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
      >
        {/* Building Outlines from dW */}
        <Source id="buildings-outline" type="geojson" data={buildingOutlineData} />
        <Layer
          source="buildings-outline"
          beforeId="waterway-label"
          type="fill-extrusion"
          paint={{
            'fill-extrusion-color': ['rgb', 100, 100, 100],
            'fill-extrusion-opacity': 1,
            'fill-extrusion-height': ['get', 'height'],
          }}
        />
        {/* The parts being drawn */}
        <Source id="building-parts" type="geojson" data={buildingEditGeoJson}>
          <Layer
            id="building-parts-layer"
            beforeId="waterway-label"
            type="fill"
            paint={{
              'fill-color': [
                'case',
                ['==', ['get', 'index'], 0],
                ['rgb', 255, 0, 0],
                ['==', ['get', 'index'], 1],
                ['rgb', 0, 0, 255],
                ['rgb', 0, 255, 0],
              ],
              'fill-opacity': 0.3,
            }}
          />
        </Source>
        {/* The "outline" part draw separately */}
        <Source id="drawn-building-outline" type="geojson" data={outlineGeoJson}>
          <Layer
            id="drawn-building-outline"
            beforeId="waterway-label"
            type="fill"
            paint={{ 'fill-color': '#ffffff', 'fill-opacity': 0.3 }}
          />
        </Source>
      </Map>
      <Map
        id="building-edit-mini-map"
        initialViewState={mainMapViewState}
        {...viewState}
        zoom={viewState.zoom - 2}
        pitch={60}
        mapStyle={mapStyleUrl}
        mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
        style={{
          width: '35%',
          height: '50%',
          position: 'absolute',
          bottom: 0,
          right: 0,
          borderColor: '#5b8da7',
          borderStyle: 'solid',
          borderTopWidth: '3px',
          borderLeftWidth: '3px',
        }}
      >
        <Source id="buildings-outline" type="geojson" data={buildingOutlineData}>
          <Layer
            id="buildings-outline"
            source="buildings-outline"
            beforeId="waterway-label"
            type="fill-extrusion"
            paint={{
              'fill-extrusion-color': ['rgb', 100, 100, 100],
              'fill-extrusion-opacity': 1,
              'fill-extrusion-height': ['get', 'height'],
            }}
          />
        </Source>
        <Source id="building-parts" type="geojson" data={buildingEditGeoJson}>
          <Layer
            id="building-parts-layer"
            beforeId="waterway-label"
            type="fill-extrusion"
            paint={{
              'fill-extrusion-color': [
                'case',
                ['==', ['get', 'index'], 0],
                ['rgb', 255, 0, 0],
                ['==', ['get', 'index'], 1],
                ['rgb', 0, 0, 255],
                ['rgb', 0, 255, 0],
              ],
              'fill-extrusion-opacity': 1,
              'fill-extrusion-height': ['+', ['get', 'baseHeight'], ['get', 'height']],
              'fill-extrusion-base': ['get', 'baseHeight'],
            }}
          />
        </Source>
      </Map>
      <VStack position="absolute" top="10px" left="10px">
        <Popover trigger="hover" placement="top-start" id={'map-layer-select'}>
          <PopoverTrigger>
            {/* Needs to be wrapped in a box to handle the forwardRef */}
            <Box>
              <MapOverlayButton>
                <BsLayersHalf color="white" size="1.4em" />
              </MapOverlayButton>
            </Box>
          </PopoverTrigger>
          <PopoverContent zIndex={100}>
            <Box padding={2}>
              <RadioGroup
                value={mapStyleUrl}
                onChange={(e) => {
                  setMapStyleUrl(e);
                }}
              >
                <VStack>
                  {[
                    { name: 'Streets', url: 'mapbox://styles/mapbox/streets-v11' },
                    { name: 'Satellite', url: 'mapbox://styles/mapbox/satellite-streets-v11' },
                  ].map((style, i) => {
                    return (
                      <Radio
                        id={`${style.name}-radio-button`}
                        key={`tilebutton${i}`}
                        value={style.url}
                      >
                        {style.name}
                      </Radio>
                    );
                  })}
                </VStack>
              </RadioGroup>
              <Divider />
            </Box>
          </PopoverContent>
        </Popover>
        <Flex id={'building-edit-map-zoom-controls'}>
          <VStack alignItems="stretch" spacing={0}>
            <MapOverlayButton
              id={'building-edit-map-zoom-in-button'}
              borderBottomRadius={0}
              onClick={() => {
                const zoomLevel = editMap?.getZoom();

                if (zoomLevel) {
                  editMap?.zoomTo(Math.round(zoomLevel) + 1);
                } else {
                  editMap?.zoomIn({});
                }
              }}
            >
              <BsPlusLg color="white" />
            </MapOverlayButton>
            <MapOverlayButton
              id={`building-edit-map-zoom-out-button`}
              borderTopRadius={0}
              onClick={() => {
                editMap?.zoomOut();
              }}
            >
              <BsDashLg color="white" />
            </MapOverlayButton>
          </VStack>
        </Flex>
        <MapOverlayButton
          id="building-edit-map-reset-pitch-control-button"
          onClick={() => {
            const pitchToSet = viewState.pitch === 0 ? 60 : 0;
            editMap?.easeTo({ pitch: pitchToSet, duration: 800 });
          }}
        >
          {viewState.pitch === 0 ? '3D' : '2D'}
        </MapOverlayButton>
        <MapOverlayButton
          id={'building-edit-map-trash-button'}
          onClick={() => {
            draw.trash();
          }}
        >
          <FaRegTrashCan color="white" size="1.2em" />
        </MapOverlayButton>
        <MapOverlayButton
          id={'building-edit-map-clear-button'}
          onClick={() => {
            draw.deleteAll().changeMode('draw_polygon');
          }}
        >
          <TbShapeOff color="white" size="1.2em" />
        </MapOverlayButton>
      </VStack>
    </Box>
  );
};

export default BuildingEditMap;
