import { Button, Flex, Spinner, useDisclosure, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import daAxiosClient from '../../services/daAxiosClient';
import LeadGenReportModal from './LeadGenReportModal';

type LeadGenReportButtonProps = {
  selectedFeatureId: string | number;
};

const LeadGenReportButton: FC<LeadGenReportButtonProps> = ({ selectedFeatureId }) => {
  const {
    isOpen: isLeadgenModalOpen,
    onOpen: onLeadgenModalOpen,
    onClose: onLeadgenModalClose,
  } = useDisclosure();

  const { isLoading: isLeadgenAvailabilityLoading, isError: isLeadgenAvailabilityError } = useQuery(
    {
      queryKey: ['leadgen-ppt', selectedFeatureId],
      queryFn: () => {
        return daAxiosClient.analysis.getLeadgenAvailability(selectedFeatureId);
      },
      retry: false,
      enabled: !!selectedFeatureId,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  return (
    <>
      {isLeadgenAvailabilityLoading ? (
        <Button id="generate-leadgen-ppt-button" ml="3" mr="auto" isDisabled={true}>
          <Text mr="2">Getting Leadgen Report</Text>
          <Spinner />
        </Button>
      ) : selectedFeatureId ? (
        <Flex>
          {isLeadgenAvailabilityError ? (
            <Button id="generate-leadgen-ppt-button-disabled" ml="3" mr="auto" isDisabled={true}>
              Leadgen Report Not Available
            </Button>
          ) : (
            <Flex>
              <Button
                id="generate-leadgen-ppt-button-enabled"
                ml="3"
                mr="auto"
                onClick={onLeadgenModalOpen}
                isDisabled={false}
              >
                Leadgen Report
              </Button>
              <LeadGenReportModal
                isOpen={isLeadgenModalOpen}
                onClose={onLeadgenModalClose}
                featureId={selectedFeatureId}
              />
            </Flex>
          )}
        </Flex>
      ) : null}
    </>
  );
};

export default LeadGenReportButton;
